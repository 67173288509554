#de-courses,
.search-container,
.search-filters {
    display: flex;
    flex: 1 1 auto;
}

#de-courses {
    flex-direction: column;
    padding: 1rem;
}

.search-container {
    padding: var(--padding-1-rem) 0;
    flex-wrap: wrap;
}

.search-filter,
.search-filters,
.filter-toggle {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    align-items: center;
}


#filter {
    width: 100%;
    padding: var(--padding-1-rem);
    border: var(--border);
    /* border-color: var(--bg-color); */
    border-radius: 3px;
    margin-bottom: 10px;
    box-sizing: border-box;
    /* color: var(--text-color); */
    font-size: 16px;
    margin: 0.25rem 0;
}

.filter-toggle {
    margin: var(--margin-1-rem);
    /* color: var(--text-color); */
}

@media screen and (max-width: 475px) { 
    .course-header.no-mobile {
      display: none;
    }
  
    .mobile-only {
      display: initial;
    }
}

@media print {
    .print-courses {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
    }

    .course-header.no-mobile {
        display: block !important;
    }
    
}
