@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700,300|Poppins:400,700,300|Lato:400,700,300|Noto+Sans:400,700|Noto+Serif:400,700);
* {
  margin: 0;
  box-sizing: border-box;
}


:root {
  --bg-color: #1B262F;
  --bg-color-darker: #151E26;
  --text-color: #282c34;
  --text-color-light: #fbfbfb;
  --text-color-gray: #bababa;
  --card-color: #fbfbfb;
  --drop-shadow: 0px 2px 5px rgba(22,22,22,.15);
  --padding-half-rem: .5rem;
  --padding-1-rem: 1rem;
  --primary-color: #2c6bfb;
  --primary-alt-color: #1860f9;
  --primary-dark-color: #004890;
  --primary-dark-color-alt: #0f49bd;
  --border-radius: 4px;
  --border: 1px solid #e8e8e8;
  --margin-quarter-rem: .25rem;
  --margin-1-rem: 1rem;
  --toggle-width: 2.25rem;
  --toggle-height: 1.25rem;
  --toggle-off-color: #e0e0e0;
  --toggle-on-color: var(--primary-color);
  --toggle-border-radius: 1.75em;
  --toggle-btn-padding: .108rem;
}

body {
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;

}

.padding {
  padding: 1rem;
  padding: var(--padding-1-rem);
}
.btn {
  text-decoration: none;
  border: none;
  box-shadow: none!important;
  display: inline-block;
  line-height: inherit;
  border-radius: 0;
  padding: 1rem;
  min-width: 200px;
  background-color: #0f49bd;
  background-color: var(--primary-dark-color-alt);
  color: #fbfbfb;
  color: var(--text-color-light);
  text-indent: 0;
  text-align: center;
  font-size: 16px;
  height: auto;
}

.print-only {
  display: none;
}

.dark {
  background-color: #1B262F;
  background-color: var(--bg-color);
  color: #fbfbfb;
  color: var(--text-color-light);
}

.darker {
  background-color: #151E26;
  background-color: var(--bg-color-darker);
  color: #bababa;
  color: var(--text-color-gray);
}

.tgl {
  display: none;
}

.tgl,
.tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + .tgl-btn {
  box-sizing: border-box;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + .tgl-btn::selection {
  background: none;
}

.tgl + .tgl-btn {
  outline: 0;
  display: flex;
  width: 2.25rem;
  width: var(--toggle-width);
  height: 1.25rem;
  height: var(--toggle-height);
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.tgl + .tgl-btn:after,
.tgl + .tgl-btn:before {
  position: relative;
  display: flex;
  content: '';
  width: 50%;
  height: 100%;
}

.tgl + .tgl-btn:after {
  left: 0;
}

.tgl + .tgl-btn:before {
  display: none;
}

.tgl:checked + .tgl-btn:after {
  left: 50%;
}

.tgl-light + .tgl-btn {
  background: #e0e0e0;
  background: var(--toggle-off-color);
  border-radius: 1.75em;
  border-radius: var(--toggle-border-radius);
  padding: .108rem;
  padding: var(--toggle-btn-padding);
  transition: all 0.4s ease;
  margin: 0.5rem;
}

.tgl-light + .tgl-btn:after {
  border-radius: 50%;
  background: white;
  transition: all 0.2s ease;
}

.tgl-light:checked + .tgl-btn {
  background: #2c6bfb;
  background: var(--toggle-on-color);
}


.course-header .field:hover {
  color: #fbfbfb;
  color: var(--text-color-light);
}

.course-header .field:hover {
  background-color: #1860f9;
  background-color: var(--primary-alt-color);
}
.course-header {
  display: table;
  padding: .5rem;
  padding: var(--padding-half-rem);
  margin: 0.5rem 0;
  border-radius: 4px;
  border-radius: var(--border-radius);
  -webkit-animation: slide-up 0.4s ease;
          animation: slide-up 0.4s ease;
  box-shadow: 0px 2px 5px rgba(22,22,22,.15);
  box-shadow: var(--drop-shadow);
  width: 100%;
}
.course-header .field {
  display: table-cell;
  padding: .5rem;
  padding: var(--padding-half-rem);
}

.course-header .field.prefix,
.course-header .field.course-number {
  width: 10%;
}
.course-header .field.level,
.course-header .field.number,
.course-header .field.credit-awarded {
  width: 2%;
}
.course-header .field.title,
.course-header .field.subject-area-requirement {
  width: 25%;
}
.mobile-only {
  display: none;
}

@-webkit-keyframes slide-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
      opacity: 0;
      transform: translateY(20px);
  }

  100% {
      opacity: 1;
      transform: translateY(0);
  }
}

@media screen and (max-width: 475px) {
  .btn {
    width: 100%;
  }

  .no-mobile {
    display: none;
  }

  .mobile-only {
    display: inline;
    display: initial;
  }
}

@media print {
  .print-only,
  .no-mobile,
  .print-show {
    display: block;
  }
}

#de-courses,
.search-container,
.search-filters {
    display: flex;
    flex: 1 1 auto;
}

#de-courses {
    flex-direction: column;
    padding: 1rem;
}

.search-container {
    padding: var(--padding-1-rem) 0;
    flex-wrap: wrap;
}

.search-filter,
.search-filters,
.filter-toggle {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    align-items: center;
}


#filter {
    width: 100%;
    padding: var(--padding-1-rem);
    border: var(--border);
    /* border-color: var(--bg-color); */
    border-radius: 3px;
    margin-bottom: 10px;
    box-sizing: border-box;
    /* color: var(--text-color); */
    font-size: 16px;
    margin: 0.25rem 0;
}

.filter-toggle {
    margin: var(--margin-1-rem);
    /* color: var(--text-color); */
}

@media screen and (max-width: 475px) { 
    .course-header.no-mobile {
      display: none;
    }
  
    .mobile-only {
      display: inline;
      display: initial;
    }
}

@media print {
    .print-courses {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
    }

    .course-header.no-mobile {
        display: block !important;
    }
    
}

@media screen and (max-width: 475px) {

    .course-header {
        display: table;
    }

    
    .course-header .field {
        display: table-row;
    }
    
    .course-header .field div {
        display: table-cell;
        width: 50%;
        border-bottom: 1px solid #eaeaea;
        padding: .25rem;
    }
}

@media print {
    .course {
        display: table;
        border-radius: var(--border-radius);
        box-shadow: var(--drop-shadow);
    }
}


