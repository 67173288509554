@media screen and (max-width: 475px) {

    .course-header {
        display: table;
    }

    
    .course-header .field {
        display: table-row;
    }
    
    .course-header .field div {
        display: table-cell;
        width: 50%;
        border-bottom: 1px solid #eaeaea;
        padding: .25rem;
    }
}

@media print {
    .course {
        display: table;
        border-radius: var(--border-radius);
        box-shadow: var(--drop-shadow);
    }
}
